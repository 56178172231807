<template>
  <AuthPageTemplate
    :errorCard="errorCardStatus"
    :loadingCard="checkingResetCode"
    :button="$t('auth.register.button.register')"
    :loading="loadingLogin || isTimeout"
    :error="errorHandle"
    :disabled="!isValidInputs"
    @click="registerUser"
  >
    <!-- invalid OOBCode -->
    <template v-slot:error>
      <v-col justify="center" align="center" style="height: 100%">
        <div
          style="
            width: 80%;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
          "
        >
          <v-icon size="60" color="primary">mdi-close-circle-outline</v-icon>
          <div>
            <b class="primary--text" style="font-size: 17px">{{
              $t('auth.register.invalidLink')
            }}</b>
          </div>
        </div>

        <TextButton
          class="back-button"
          v-on:click="$router.push({name: 'login'}).catch(() => {})"
          dense
          icon="mdi-chevron-left"
          :text="$t('auth.register.button.toLogin')"
        />
      </v-col>
    </template>
    <template v-slot:input>
      <div class="black--text mb-6" style="font-size: 15px">
        <div
          style="font-size: 20px; text-align: start"
          class="mb-1 black--text"
        >
          <b>{{ $t('auth.register.title') }}</b>
        </div>
        <span style="font-size: 15px" class="grey--text">{{
          $t('auth.register.text')
        }}</span>
      </div>
      <div style="height: 280px">
        <!-- EMAIL INPUT -->
        <TextInput
          :disabled="loadingLogin"
          v-on:change="updateMailEvent"
          :placeholder="$t('auth.register.input.mail')"
          :value="registerObj.mail"
        />
        <v-row class="ma-0" align="center">
          <!-- FIRST NAME -->
          <TextInput
            class="mt-2"
            v-on:change="updateNameEvent"
            :disabled="loadingLogin"
            :placeholder="$t('auth.register.input.name')"
            style="width: 20%"
            :value="registerObj.name"
          />
          <!-- LAST NAME -->
          <TextInput
            class="mt-2 ml-1"
            style="width: 20%"
            :disabled="loadingLogin"
            v-on:change="updateLastNameEvent"
            :placeholder="$t('auth.register.input.lastname')"
            :value="registerObj.lastname"
          />
        </v-row>

        <!-- PASSWORD INPUT FIRST TIME -->
        <TextInput
          class="mt-6"
          :disabled="loadingLogin"
          v-on:change="updatePasswordEvent"
          :placeholder="$t('auth.register.input.password1')"
          password
          id="password"
          v-on:focus="focusEvent"
          :value="registerObj.password"
        />
        <!-- SHOW STRENGTH OF PASSWORD -->
        <v-expand-transition>
          <PasswordStrength
            v-show="showPasswordCheck"
            :disabled="!showPasswordCheck || loadingLogin"
            class="ml-6"
            :password="registerObj.password"
          />
        </v-expand-transition>
        <!-- PASSWORD INPUT SECOND TIME -->
        <TextInput
          class="mt-2"
          password
          :disabled="loadingLogin"
          v-on:change="updatePasswordVerificationEvent"
          :placeholder="$t('auth.register.input.password2')"
          :value="registerObj.passwordVerification"
        />
        <v-row class="ma-0 mb-6 mt-2" align="start">
          <!-- CHECK TERM CONDITION -->
          <v-checkbox
            dense
            class="mt-3"
            :ripple="false"
            :disabled="loadingLogin"
            hide-details
            v-model="acceptTermsConditions"
            ><template v-slot:label>
              <div
                :class="!loadingLogin ? 'black--text' : 'grey--text'"
                style="font-size: 14px; height: 24px"
              >
                {{ $t('auth.register.terms.textStart') }}
                <span
                  v-on:click="clickTerms(1, 'https://alinotec.de/agbs-avbs/')"
                  :class="!loadingLogin ? '' : 'grey--text'"
                  class="links"
                  >{{ $t('auth.register.terms.terms') }}</span
                >
                &
                <span
                  v-on:click="clickTerms(2, 'https://parkort.io/?page_id=141')"
                  :class="!loadingLogin ? '' : 'grey--text'"
                  class="links"
                  >{{ $t('auth.register.terms.privacy') }}</span
                >
                {{ $t('auth.register.terms.textEnd') }}
              </div>
            </template></v-checkbox
          >
        </v-row>
      </div>
    </template>
    <!-- <template v-slot:bottom>
      <TextButton :disabled="loadingLogin" dense icon="mdi-chevron-left" v-on:click="$router.push('login')" class="mt-2" block text="Zur Anmeldung"/>
    </template> -->
  </AuthPageTemplate>
</template>

<script>
import AuthPageTemplate from '@/components/auth/AuthPageTemplate.vue';
import TextInput from '@components/text/TextInput.vue';
import TextButton from '@components/button/TextButton.vue';
import PasswordStrength from '@/components/auth/PasswordStrength.vue';

export default {
  name: 'RegisterView',
  components: {
    AuthPageTemplate,
    PasswordStrength,
    TextInput,
    TextButton,
  },

  data() {
    return {
      showPasswordCheck: false,
      isTimeout: false,
      inviteHandle: null,
      acceptTermsConditions: false,
      loadCard: false,
      loadingLogin: false,
      errorHandle: {
        status: false,
        text: '',
      },

      checkingResetCode: true,

      errorCardStatus: false,

      registerObj: {
        id: '',
        name: '',
        lastname: '',
        mail: '',
        tempPassword: '',
        password: '',
        passwordVerification: '',
      },
    };
  },

  computed: {
    isValidInputs() {
      const verifyNameAndLastName = () => {
        return (
          this.registerObj.name != null &&
          this.registerObj.name.length > 0 &&
          this.registerObj.lastname != null &&
          this.registerObj.lastname.length > 0
        );
      };

      const verifyAcceptTermsConditions = () => {
        return this.acceptTermsConditions == true;
      };

      const verifyPassword = () => {
        return (
          this.registerObj.password != null &&
          this.registerObj.password.length >= 6 &&
          this.registerObj.passwordVerification == this.registerObj.password
        );
      };

      if (
        verifyNameAndLastName() &&
        verifyAcceptTermsConditions() &&
        verifyPassword()
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  beforeCreate() {
    document.title = this.$route.meta.tab || this.$product;
  },

  async created() {
    const resp = await this.$auth.verifyRegisterCode(this.$route.query.oobCode);
    if (resp == null) {
      this.errorCardStatus = true;
    }

    this.checkingResetCode = false;
    // if (this.$route.query.id == null) return this.$router.push({name: 'login'});

    this.loadCard = true;
    this.inviteHandle = this.$route.query.invite;
    var mail = this.$route.query.mail;

    var tempPassword = this.$route.query.p || '';
    // this.$router.replace({'query': null});
    this.registerObj.mail = mail || '';
    this.registerObj.tempPassword = tempPassword || '';
  },

  methods: {
    focusEvent(eventHandle) {
      if (eventHandle.type == 'focus') {
        this.showPasswordCheck = true;
      } else if (eventHandle.type == 'blur') {
        this.showPasswordCheck = false;
      }
    },

    clickTerms(type, url) {
      this.$helper.openTab(url);
      this.acceptTermsConditions = !this.acceptTermsConditions;
    },
    updateNameEvent(nameString) {
      this.registerObj.name = nameString;
      this.error = null;
    },
    updateLastNameEvent(nameString) {
      this.registerObj.lastname = nameString;
      this.error = null;
    },
    updateMailEvent(mailString) {
      this.registerObj.mail = mailString.trim().toLowerCase();
      this.error = null;
    },
    updatePasswordEvent(passwordString) {
      this.registerObj.password = passwordString;
      this.error = null;
    },
    updatePasswordVerificationEvent(passwordString) {
      this.registerObj.passwordVerification = passwordString;
      this.error = null;
    },

    async registerUser() {
      this.loadingLogin = true;

      // console.log(this.registerObj.tempPassword)
      var authResp = await this.$auth.login(
        this.registerObj.mail,
        this.registerObj.tempPassword,
      );

      if (authResp.success == true) {
        await this.$auth.updateUsername(
          this.registerObj.mail,
          this.registerObj.tempPassword,
          `${this.registerObj.name} ${this.registerObj.lastname}`,
        );
        await this.$auth.updatePassword(
          this.registerObj.mail,
          this.registerObj.tempPassword,
          this.registerObj.password,
        );

        authResp = await this.$auth.login(
          this.registerObj.mail,
          this.registerObj.password,
        );
        if (authResp.success == true) {
          authResp = await this.$auth.verifyMail(this.$route.query.oobCode);
          if (authResp == false) {
            this.errorHandle.status = true;
            this.errorHandle.text = this.$t('auth.register.error.retry');
            this.loadingLogin = false;
          } else {
            return this.$router.push({name: 'login'});
          }
        }
      } else {
        this.errorHandle.status = true;
        this.errorHandle.text = this.$t('auth.register.error.retry');
      }

      this.loadingLogin = false;
    },
  },
};
</script>

<style scoped>
.terms-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.links-container {
  position: absolute;
  top: 10px;
  left: 15px;
}

.links {
  user-select: none;
  text-decoration: underline;
  cursor: pointer !important;
  text-decoration: none;
  color: black;
}

.links:hover {
  color: var(--v-primary-base);
}

.back-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  border: 1px solid #c0c0c0;
}
</style>
